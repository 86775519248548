jQuery(function($){
    if($.fn.prettyPhoto){
        var $settings = {
            social_tools: false,
            show_title: true,
            deeplinking: false,
            theme: 'pp_default',
            overlay_gallery: false,
            allow_expand: false,
            opacity: 0.7,
            markup: '<div class="pp_pic_holder"> \
						<div style="display: none;"><div class="ppt">&nbsp;</div></div> \
						<div class="pp_top"> \
							<div class="pp_left"></div> \
							<div class="pp_middle"></div> \
							<div class="pp_right"></div> \
						</div> \
						<div class="pp_content_container"> \
							<div class="pp_left"> \
							<div class="pp_right"> \
								<div class="pp_content"> \
									<div class="pp_loaderIcon"></div> \
									<div class="pp_fade"> \
										<a href="#" class="pp_expand" title="Expand the image">Expand</a> \
										<div class="pp_hoverContainer"> \
											<a class="pp_next" href="#">next</a> \
											<a class="pp_previous" href="#">previous</a> \
										</div> \
										<div id="pp_full_res"></div> \
										<div class="pp_details"> \
											<div class="pp_nav"> \
												<a href="#" class="pp_arrow_previous">Previous</a> \
												<p class="currentTextHolder">0/0</p> \
												<a href="#" class="pp_arrow_next">Next</a> \
											</div> \
											<p class="pp_description"></p> \
											{pp_social} \
											<a class="pp_close" href="#">Close</a> \
										</div> \
									</div> \
								</div> \
							</div> \
							</div> \
						</div> \
						<div class="pp_bottom"> \
							<div class="pp_left"></div> \
							<div class="pp_middle"></div> \
							<div class="pp_right"></div> \
						</div> \
					</div> \
					<div class="pp_overlay"></div>'
        };
                
        $("a[href*='.png']:not(.no-prettyPhoto), a[href*='.jpg']:not(.no-prettyPhoto), a[href*='.jpeg']:not(.no-prettyPhoto), a[href*='.gif']:not(.no-prettyPhoto), a[href*='.bmp']:not(.no-prettyPhoto)").prettyPhoto($settings);

        $("a[href*='youtube']:not(.no-prettyPhoto)").prettyPhoto($settings);
        $("a[href*='vimeo']:not(.no-prettyPhoto)").prettyPhoto($settings);

        $('a[rel=prettyPhoto]').prettyPhoto($settings);
    }
});
