(function(window, document){
    window.$ = jQuery.noConflict();

    $.fn.visible = function ($val) {
        if (!$val) {
            $(this).css('visibility', 'hidden');
        } else {
            $(this).css('visibility', 'visible');
        }
    };

    $.fn.grid_layout = function ($grid_options) {
        if (!$.fn.isotope)
            return false;

        $(this).each(function () {
            var $first_load = $(this).data('first-load')||1;
            var $item_grid = $(this).isotope($grid_options);
            $item_grid.on('layoutComplete', function (isoInstance, laidOutItems) {
                if ($first_load == 1) {
                    var $duration = 300;
                    $.each(laidOutItems, function ($idx, $item) {
                        $($item.element).delay($idx * $duration).animate({'opacity': 1}, function () {
                            $(this).addClass('loaded');
                        });
                    });
                    $first_load = 2;
                } else {
                    $.each(laidOutItems, function ($idx, $item) {
                        $($item).stop().addClass('loaded');
                    });
                }

                $(this).data('first-load', $first_load);

            });
            $item_grid.isotope('bindResize');
            $item_grid.isotope('layout');
        });

        return true;
    };


    function preload_pictures($container, $callback) {
        if ($container.length == 0)
            return;

        var $ = jQuery;
        $container.addClass('loading');

        var $imgs = [];
        $container.find('img').each(function () {
            var $src = $(this).attr('src');
            if ($src)
                $imgs.push($src);
        });

        var defer = $.imgpreloader({
            paths: $imgs
        });

        defer.always(function ($allImages, $properImages, $brokenImages) {
            $container.removeClass('loading');
            $callback();
        });

    }

    $(document).ready(function () {

        var $body = $('body');
        var $window = $(window);

        $('#main-menu').each(function () {
            var $me = $(this);

            $me.find('.menu-item').hover(function () {
                clearTimeout(window._timer_sub_menu);

                // find previous menus
                $(this).prevAll().find('.sub-menu').hide();
                $(this).prevAll().removeClass('hover');
                // find next menus
                $(this).nextAll().find('.sub-menu').hide();
                $(this).nextAll().removeClass('hover');

                if ($(this).find('>.sub-menu').length == 0)
                    return;

                $(this).find('>.sub-menu').slideDown();
                $(this).addClass('hover');


            }, function () {

            });

            $me.hover(function () {

            }, function () {
                window._timer_sub_menu = setTimeout(function () {
                    $('#main-menu .sub-menu').hide();
                    $('#main-menu .menu-item').removeClass('hover');
                }, 500);

            });

            $me.find('.current-menu-item').each(function () {
                $(this).parents('.menu-item').addClass('current-menu-item');
            });
        });

        $('#menu-responsive-button').each(function () {
            $(this).click(function ($e) {
                var $menu = $('#main-menu .menu-container');
                $menu.slideToggle();

                $e.stopPropagation();
                return false;
            });


            $(document).on('click', function () {
                var $menu = $('#main-menu .menu-container');
                if ($('#menu-responsive-button').is(':visible') && $menu.is(':visible')) {
                    $menu.hide();
                }
            });
        });

        if ($.fn.flexslider) {
            if ($('.bg-slider').length) {
                var $row_delay = 2000;

                $(window).on('resize.bg_slider', function(){
                    $('.bg-slider').css('height', $(window).height());
                });

                $(window).trigger('resize.bg_slider');


                $('.bg-slider').addClass('js');
                $('.bg-slider').addClass('loading');
                preload_pictures($('.bg-slider'), function () {
                    $('.bg-slider').removeClass('loading');
                    $('.cta-blocks').bind('do_effect', function () {
                        var $me = $(this);
                        var $animate = $(this).data('animate');
                        $animate = $animate ? $animate : 'fadeInUp';
                        if (Modernizr.cssanimations) {
                            $me.stop().visible(true);
                            $me.addClass('animated ' + $animate).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                                $(this).removeClass('animated');
                                $(this).removeClass($animate);
                            });
                        } else {
                            $me.stop().visible(true);
                        }
                    });

                    $('.bg-slider .img').bind('active', function () {
                        var $slide = $(this);

                        $('.cta-blocks').clearQueue().stop(true).visible(false);

                        $('.main-block', $slide).delay($row_delay).queue(function () {
                            $(this).trigger('do_effect');
                        });                        
                        $('.second-block', $slide).delay($row_delay * 2).queue(function () {
                            $(this).trigger('do_effect');
                        });
                    });

                    $('.bg-slider .img').bind('deactive', function () {
                        var $current_slide = $(this);
                        $('.cta-blocks').clearQueue().stop(true);
                        $('.cta-blocks', $current_slide).removeClass('animated');
                        $('.cta-blocks', $current_slide).visible(false);
                        //$('body').removeClass('slide-caption-hover');
                    });

                    $('.bg-slider').flexslider({
                        animation: 'fade',
                        animationLoop: true,
                        pauseOnAction: false,
                        controlNav: false,
                        directionNav: false,
                        smoothHeight: false,
                        video: false,
                        slideshowSpeed: 15000,
                        animationSpeed: 2000,
                        selector: ".img",
                        start: function ($slider) {
                            var $current_slide = $slider.find('.img:first');
                            $('.caption', $current_slide).stop().show();

                            $current_slide.trigger('active');

                        },
                        before: function ($slider) {
                            var $current_slide = $slider.find('.flex-active-slide').next();
                            if (!$current_slide.length) {
                                $current_slide = $slider.find('.img:first');
                            }

                            $current_slide.trigger('deactive');
                        },
                        after: function ($slider) {
                            var $current_slide = $slider.find('.flex-active-slide');
                            $('.caption', $current_slide).stop().show();

                            $current_slide.trigger('active');
                        }
                    });

                    $('.bg-slider-nav').click(function () {
                        if (window._bg_slider_inaction) {
                            clearTimeout(window._bg_slider_inaction);
                            window._bg_slider_inaction = false;
                        }

                        if ($(this).hasClass('previous')) {
                            $('.bg-slider').flexslider('prev');
                        } else {
                            $('.bg-slider').flexslider('next');
                        }

                        window._bg_slider_inaction = setTimeout(function () {
                            $('.bg-slider').flexslider('play');
                        }, 10000);

                        return false;
                    });


                });
                /*
                $('body').on('mouseover', function(){
                    $('body').removeClass('slide-caption-hover');
                });
                $('body').on('mouseover', '.bg-slider .caption-item-2', function($e){
                    $('body').addClass('slide-caption-hover');

                    $e.stopPropagation();
                });*/

            }
        }

        if(!Modernizr.touch && window.skrollr){
            var sr = skrollr.init({
                    forceHeight : false
                }
            );

        }

        if ($('.grid-layout').length) {
            var $grid_layout = $('.grid-layout');
            var $grid_items = $('.grid-layout .post');

            preload_pictures($grid_layout, function () {
                var $grid_options = {
                    itemSelector: '.post',
                    masonry: {
                        columnWidth: '.grid-sizer',
                        gutter: '.gutter-sizer'
                    }
                };

                $grid_layout.grid_layout($grid_options);
            });

            $grid_items.bind('active', function () {
                $(this).addClass('active');
                var $default_class = $(this).data('class');
                var $to_class = $(this).data('to');
                $(this).removeClass($default_class);
                $(this).addClass($to_class);
            });

            $grid_items.bind('deactive', function () {
                $(this).removeClass('active');
                var $default_class = $(this).data('class');
                var $to_class = $(this).data('to');

                $(this).removeClass($to_class);
                $(this).addClass($default_class);

            });

            $grid_items.on('click', '.post-inner, .post-close', function () {
                var $post = $(this).closest('.post');
                if ($post.hasClass('active')) {
                    $post.trigger('deactive');
                } else {
                    $post.prevAll('.post').add($post.nextAll('.post')).trigger('deactive');
                    $post.trigger('active');
                }

                $grid_layout.isotope('layout');

                return false;
            });
        }

        $('body').on('click', '.people-list .person-inner, .people-list .post-close', function(){
            var $people_list = $('.people-list');
            var $parent = $(this).closest('.post');
            var $detail = $parent.find('.person-detail');

            if($parent.hasClass('active')){
                $parent.removeClass('active');
                $detail.fadeOut();
            }else{
                $parent.addClass('active');

                $('.scroll-content', $detail).height($people_list.height());

                $detail.fadeIn();

                if($.fn.mCustomScrollbar){
                    $('.scroll-content', $detail).mCustomScrollbar('update');
                }

                var $view_top = $('#header').height();
                if($('#wpadminbar').length){
                    $view_top += 30;
                }
                var $bound = $detail.get(0).getBoundingClientRect();
                if($bound.top < $view_top){
                    var $top = $detail.offset().top - $view_top;

                    $('html, body').animate({
                        scrollTop: $top
                    }, 600);
                }
            }

            return false;
        });

        $('body').on('click', '.people-list .person-detail', function($e){
            $e.stopPropagation();
        });

        $('body').on('click', function(){
            $('.people-list .active .post-close').trigger('click');
        });

        if($.fn.mCustomScrollbar){
            $('.scroll-content').mCustomScrollbar({
                theme:"light"
            });
        }

        // $('body').on('click', '.client-login', function(){
        //     $('#client-login-modal').modal({
        //         'show': true
        //     });
        //     return false;
        // });

        $(window).on('scroll.scrolled', function(){
            var $top = $(this).scrollTop();
            if($top <= 2){
                $('body').removeClass('scrolled');
            }else{
                $('body').addClass('scrolled');
            }
        });
        $(window).trigger('scroll.scrolled');

        if($('.service-steps').length){
            console.log('me workee');
            var $service_container = $('.service-steps');
            var $step_items = $('.service-steps .step-item');
            var $step_backgrounds = $('.service-backgrounds .step-bg');

            $step_backgrounds.bind('active', function(){
                var $t = $(this);
                $t.addClass('active');
            });

            $step_backgrounds.bind('deactive', function(){
                var $t = $(this);
                $t.removeClass('active');
            });

            $step_items.bind('active', function(){
                var $t = $(this);
                $t.addClass('active');

                var $index = $t.index();
                var $tbg = $step_backgrounds.eq($index);
                if($tbg.length){
                    $tbg.trigger('active');
                }
            });

            $step_items.bind('deactive', function(){
                var $t = $(this);
                $t.removeClass('active');

                var $index = $t.index();
                var $tbg = $step_backgrounds.eq($index);
                if($tbg.length){
                    $tbg.trigger('deactive');
                }
            });

            $service_container.bind('update_steps_width', function(){
                var $main = $('#main');
                var $main_width = $main.width();
                var $window_width = $window.width();

                if($window_width < 768){
                    $step_items.css('width', false);

                    return;
                }

                if($window_width < 992 ){
                    $main_width = $window_width;

                }

                if($window_width >=1400) {
                    $main_width = $main_width*1.25;
                }

                //$main_width = $main_width*0.8; // get 80%

                var $step_item_width = $main_width/$step_items.length;
                var $item_wrapper_width = $step_items.eq(0).find('.step-item-wrapper').width();

                $step_items.css('width', $step_item_width + 'px');
                $step_items.find('.step-number-button').css('width', $step_item_width + 'px');
                $step_items.find('.step-description').css('width', ($item_wrapper_width - $step_item_width - 20) + 'px');
            });

            $service_container.bind('align_steps', function(){
                var $step = $step_items.filter('.active');

                var $window_width = $window.width();

                var $main = $('#main');
                var $main_width = $main.width();


                if($window_width <= 480){
                    $service_container.css('margin-right', 'auto');
                    $service_container.css('margin-left', 'auto');

                    return;
                }

                var $index = $step.index();

                if($step.length == 0 || ($index+1) <= $step_items.length/2){

                    //$main_width *= 0.8;
                    if($window_width >=1400) {
                        $main_width = $main_width*1.25;
                    }

                    var $margin_left = ($window_width - $main_width)/2;
                    $service_container.css('margin-right', 'auto');
                    $service_container.css('margin-left', $margin_left + 'px');

                }else{
                    $main_width = $window_width - 30;
                    var $service_container_width = $service_container.width();
                    var $margin_left = $main_width - $service_container_width;
                    $service_container.css('margin-right', 'auto');
                    $service_container.css('margin-left', $margin_left + 'px');
                }
            });

            $body.on('click', '.step-number-button a', function(){
                var $t = $(this).closest('.step-item');

                if($t.hasClass('active')){
                    $t.trigger('deactive');
                }else{
                    $t.prevAll().trigger('deactive');
                    $t.nextAll().trigger('deactive');

                    $t.trigger('active');
                }

                $service_container.trigger('align_steps');

                return false;
            });

            $window.on('resize.update_steps_width', function(){
                $service_container.trigger('update_steps_width');
                $service_container.trigger('align_steps');
            });

            $window.trigger('resize.update_steps_width');
        }

    });
})(window, document);